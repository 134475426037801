import axios from "axios";

import DefaultConfig from "../backendConfig.json";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";


export const brandAxios = axios.create({
  baseURL: DefaultConfig.BASE_URL,
});

export const authAxios = axios.create({
  baseURL: DefaultConfig.AUTH_AXIOS,
});

export const generateAxiosError = (message, config) => {
  const networkError = new Error(message);
  networkError.config = config;
  networkError.isAxiosError = true;
  return Promise.reject(networkError);
};
